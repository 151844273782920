import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import Collapse from 'common/Collapse';
import { IconButton, Icon } from 'common/styles';
import UserMenu from './UserMenu';
import useScrollTrigger from 'common/useScrollTrigger';
import MenuLinks from './MenuLinkList';
import DropdownLink from './DropdownLink';
import DropdownMenu from './DropdownMenu';
import { __t } from 'common/i18n';
import GlobalSearch from '../GlobalSearch';
import { GlobalDataType } from 'common/apiService';
import useSWR from 'swr';
import { IconHamburger, IconClose } from 'common/icons';
import { useNotifications } from 'common/AlertService';
import { globalPadding } from 'common/Layout/Layout';
import SubscriptionButton from './SubscriptionButton';
import { useRouter } from 'next/router';
import { useUser } from 'common/CacheProvider';
import { SystemMessage } from 'common/Header/SystemMessage';
import lscache from 'lscache';

import MenuBrand from './MenuBrand';
export const headerHeight = '56px';

export const HeaderContainer = styled.header<{
  solid?: boolean; scrollToTop?: boolean, system_message: SystemMessage | null }>(({ theme, solid, scrollToTop, system_message }) => {
    const bg = theme.palette.background[800][0];
    const fg = theme.palette.foreground[900][0];

    return ({
      position: 'fixed',
      top: scrollToTop ? '-56px' : system_message && !system_message?.isClosedBefore ? '48px' : 0,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.appBar,
      color: fg,
      background: `linear-gradient(to bottom, ${bg}, rgba(0,0,0,0))`,
      backgroundColor: solid ? theme.rgba(bg, 1) : theme.rgba(bg, 0),
      transition: 'background-color 0.2s ease, top 0.2s ease-in-out',
      [theme.breakpoints.down('md')]: {
        top: system_message && !system_message?.isClosedBefore ? '72px' : 0,
      },
    });
  });

const AppBar = styled.div<{ solid?: boolean; }>(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  height: headerHeight,
  padding: theme.spacing(2, 0),
  color: theme.palette.foreground[900][0],
  ...theme.mixins.create(globalPadding, (p) => ({ padding: `0 ${theme.spacing(p)}` })),
  '& .hamburger': {
    marginLeft: theme.spacing(1),
  },
}));

const MenuWrapper = styled.div(({ theme }) => ({
  marginTop: '-1em',
  padding: theme.spacing(0, 2),
  boxShadow: '0 1rem 1rem rgba(0,0,0,.3)',
  zIndex: -1,
}));

const fetcher = (url: string) => fetch(url).then((res) => res.json());

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const trigger = useScrollTrigger(50);
  const [filmOpen, setFilmOpen] = useState(false);
  const [seriesOpen, setSeriesOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const notifications = useNotifications();
  const [scrollToTop, setScrollToTop] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(5.6);
  const [system_message, setSystemMessage] = useState<SystemMessage | null>(null);

  const router = useRouter();

  const { data } = useSWR<GlobalDataType>('/api/global-data', fetcher);

  const handleClose = () => {
    setMenuOpen(false);
  };

  const handleOpen = () => {
    setMenuOpen(true);
  };

  const handleSearchOpen = (open: boolean) => {
    if (open) setMenuOpen(false);
    setSearchOpen(open);
  };

  useEffect(() => {
    if (data) setLoading(false);
  }, [data]);

  const setPreScrollOffset = () => {
    const position = window ? window.scrollY : 0;
    setTimeout(() => {
      setScrollPosition(position);
    }, 50);
  };

  useEffect(() => {
    setSystemMessage(lscache.get('system_message'));
    window.addEventListener('storage', handleSystemMessage);
    window.addEventListener('scroll', setPreScrollOffset);
    return () => {
      window.removeEventListener('storage', handleSystemMessage);
      window.removeEventListener('scroll', setPreScrollOffset);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window && window.screen.width > 960 && window.scrollY > scrollPosition) {
      setScrollToTop(true);
    }
    if (window && (window.scrollY < scrollPosition || window.scrollY === 0)) {
      setScrollToTop(false);
    }
  }, [scrollPosition, router.asPath]);

  const handleSystemMessage = () => {
    setSystemMessage(lscache.get('system_message'));
  };

  const solidHeader = menuOpen || trigger || filmOpen || seriesOpen || notifications.length > 0;
  const { hasSubscription, subscriptionRemainingDays } = useUser();
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <HeaderContainer id="main-menu" solid={solidHeader} scrollToTop={scrollToTop && !menuOpen} system_message={system_message}>
        <AppBar>
          <IconButton
            className="mobile-only hamburger"
            variant="flat"
            onClick={() => { menuOpen ? handleClose() : handleOpen(); }}
          >
            <Icon size="large" cursor="pointer">{menuOpen ? <IconClose /> : <IconHamburger />}</Icon>
          </IconButton>
          <MenuBrand />
          <DropdownLink
            onOpen={() => setFilmOpen(true)}
            onClose={() => setFilmOpen(false)}
            href="/contents?types=single_video"
            title={__t('content.type.movie')}
          >
            <DropdownMenu data={data} loading={loading} type="single_video" />
          </DropdownLink>
          <DropdownLink
            onOpen={() => setSeriesOpen(true)}
            onClose={() => setSeriesOpen(false)}
            href="/contents?types=series"
            title={__t('content.type.tv_show')}
          >
            <DropdownMenu data={data} loading={loading} type="series" />
          </DropdownLink>
          <div style={{ display: searchOpen ? 'none' : 'block' }}>
            <MenuLinks data={data} className='desktop-only' />
          </div>
          <GlobalSearch onOpen={(e) => handleSearchOpen(e)} />
          {!hasSubscription || subscriptionRemainingDays <= 5 ? <SubscriptionButton /> : <div style={{ marginRight: '16px' }} />}
          <UserMenu scrollToTop={scrollToTop} />
        </AppBar>
        <Collapse className='mobile-only' fullWidth open={menuOpen}>
          <MenuWrapper>
            <MenuLinks data={data} />
          </MenuWrapper>
        </Collapse>
      </HeaderContainer>
    </ClickAwayListener>
  );
}
