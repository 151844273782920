import 'common/polyfill';
import 'sanitize.css';
import 'common/styles/global.css';
import 'public/fonts/dana.css';
import { ThemeProvider } from '@emotion/react';
import theme from 'common/theme';
import { LanguageProvider, __t } from 'common/i18n';
import { CacheProvider } from 'common/CacheProvider';
import Head from 'next/head';
import { Main } from 'common/styles';
import RouteChangeLoading from 'common/RouteChangeLoading';
import { SWRConfig } from 'swr';
import { AlertServiceContainer } from 'common/AlertService';
import TagManager from 'react-gtm-module';
import Script from 'next/script';
import useScrollRestore from 'common/useScrollRestore';
import getConfig from 'next/config';
import SystemMessage from 'common/Header/SystemMessage';
import TvNoticeBanner from '../common/Footer/TvNotice';
// import { RouterScrollProvider } from '@moxy/next-router-scroll';

const { publicRuntimeConfig } = getConfig();

const swrConfig = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  refreshInterval: 1800000,
  dedupingInterval: 300000,
  focusThrottleInterval: 600000,
};

if (typeof window === 'object') {
  const gtmId = publicRuntimeConfig.NEXT_PUBLIC_GTM_ID;
  if (gtmId) TagManager.initialize({ gtmId });
}

export default function App({ Component, pageProps }) {
  useScrollRestore();
  return (
    <>
      <SWRConfig value={swrConfig}>
        <LanguageProvider>
          <ThemeProvider theme={theme}>
            <CacheProvider>
              <Head>
                <title>{__t('siteName')}</title>
                <meta name="description" content={__t('site_description')}/>
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
                <meta name="msapplication-TileColor" content="#1c1c1c"/>
                <meta name="theme-color" content="#ffffff"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                {/* <link rel="manifest" href="/site.webmanifest" /> */}
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#aa0a00"/>
              </Head>
              <RouteChangeLoading/>
              <SystemMessage/>
              <Main>
                <AlertServiceContainer/>
                {/* <RouterScrollProvider disableNextLinkScroll={false}> */}
                <Component {...pageProps} />
                {/* </RouterScrollProvider> */}
              </Main>
              <TvNoticeBanner />
            </CacheProvider>
          </ThemeProvider>
        </LanguageProvider>
      </SWRConfig>
      <Script type="text/javascript" src="/static/goftino/index.js" strategy="lazyOnload"/>
    </>
  );
}
