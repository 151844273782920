import React, { useEffect, useState, ReactNode } from 'react';
import styled from '@emotion/styled';
import { useProfile, useUser } from 'common/CacheProvider';
import { Link, Button, Placeholder } from 'common/styles';
import {
  IconFilledBookmark,
  IconUser,
  IconSubscription,
} from 'common/icons';
import Menu from 'common/Menu';
import ProfileMenu from 'common/ProfileMenu';
import { __t } from 'common/i18n';
import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import SubscriptionTooltip from 'common/SubscriptionTooltip';
import IconUserSetting from '../icons/IconUserSetting';
import UserAvatar from './UserAvatar';

const StyledMenu = styled(Menu)(() => ({ width: '18.75rem' }));

const AvatarButton = styled.a(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
  padding: 0,
  fontSize: theme.spacing(4),
  borderRadius: '10rem',
  overflow: 'hidden',
}));
type linkType = { label: string, icon: ReactNode, href: string };

export default function UserMenu({ scrollToTop }: { scrollToTop?: boolean }) {
  const { user, hasSubscription, loaded, subscriptionRemainingDays } = useUser();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const { profile } = useProfile();
  const subscriptionButtonMessage = (!hasSubscription || subscriptionRemainingDays <= 0) ? 'subscription.buy' : 'subscription.extend';
  const [links, setLinks] = useState<linkType[]>([
    {
      label: subscriptionButtonMessage,
      icon: <IconSubscription />,
      href: '/packages',
    },
    {
      label: 'profile.bookmarks',
      icon: <IconFilledBookmark />,
      href: '/user/bookmarks',
    },
    {
      label: 'profile.title',
      icon: <IconUser />,
      href: '/user',
    },
  ]);
  useEffect(() => {
    const _setting = {
      label: 'profile.edit.profiles',
      icon: <IconUserSetting />,
      href: '/profile/choose',
      // href: `/profile/choose?redirect=${router.asPath}`,
    };
    if (profile && String(profile.isPrimary) === 'true') {
      const isDuplicate = links?.some((item) => item.label === _setting.label && item.href === _setting.href);
      if (!isDuplicate) {
        setLinks((prevState) => [_setting, ...prevState]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const router = useRouter();
  const loginUrl = `/login?redirect=${router.asPath}`;
  const { breakpoints } = useTheme();

  const handleClick = () => {
    if (window.innerWidth < breakpoints.md) {
      router.push('/user/menu');
    } else {
      setOpen((prev) => !prev);
    }
  };

  if (!loaded) return <Loading redirect={loginUrl} />;
  if (!user) return <LoginButton redirect={loginUrl} />;

  const handleShowTooltip = (subscribe, days): boolean => {
    if (user.hide_logout) {
      return false;
    } else if (subscribe) {
      if (days === 0) {
        return false;
      } else if (days > 0 && days <= 5) {
        return true;
      }
      return false;
    }
    return true;
  };
  return (
    <>
      <div style={{ position: 'relative' }}>
        <AvatarButton ref={setReferenceElement} onClick={handleClick}>
          <UserAvatar user={user} size='small' />
        </AvatarButton>
        {loaded && handleShowTooltip(hasSubscription, subscriptionRemainingDays)
          ? <div style={{ transition: 'top 0.2s ease-in-out', left: '-12px', position: 'absolute', top: scrollToTop ? '-20px' : '32px' }}>
            <SubscriptionTooltip days={subscriptionRemainingDays} />
          </div> : null}
      </div>
      <StyledMenu onClose={() => setOpen(false)} anchor={referenceElement} open={open} placement="bottom-end"
        wrapperStyles={{ zIndex: 1299 }} strategy="fixed"
      >
        <ProfileMenu user={user} links={links} showStatus isPopup />
      </StyledMenu>
    </>
  );
}

type LoadingButtonProps = { redirect: string };

const LoginButton = ({ redirect }: LoadingButtonProps) => (
  <Button variant="secondary" inputSize="small" as={Link} href={redirect}>
    {__t('auth.login')}
  </Button>
);

const Loading = ({ redirect }: LoadingButtonProps) => (
  <AvatarButton as={Link} href={redirect}>
    <Placeholder width={1} />
  </AvatarButton>
);
